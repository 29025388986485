import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../../../components/seo"

import { IconMail, IconMailSplash } from "../../../atoms/Icons"

import LatestEpisodes from "../widgets/LatestEpisodes"
import ShareSocial from "../widgets/ShareSocial"
import Contribute from "../widgets/Contribute"

const Podcast = () => {
  return (
    <>
      <div className="absolute top-0 -left-14 lg:scale-150">
        <StaticImage
          height={302}
          src="../../../images/BackgroundElements/JuicyPausePodcast/Rose-01.png"
          alt="Rose"
          placeholder="tracedSVG"
          className="z-50"
        />
      </div>
      <div className="absolute top-3/4 right-0">
        <StaticImage
          height={619}
          src="../../../images/BackgroundElements/JuicyPausePodcast/VenusdeMilo.png"
          alt="Rose"
          placeholder="tracedSVG"
          className="z-50"
        />
      </div>
      <Seo title="Podcast" />
      <div className="relative flex flex-col items-center gap-4 lg:gap-24 pb-16  mx-auto">
        <div className="flex flex-col max-w-mobile lg:max-w-7xl text-center mobile-paragraph lg:desktop-paragraph gap-6">
          <h1 className="mobile-title lg:desktop-title lg:pb-0 pt-8">
            The Juicy Pause Podcast
          </h1>
          <p>
            <span className="lg:desktop-paragraph-juicy pr-1">
              The Juicy Pause
            </span>{" "}
            is a series of conversations and musings on the choices we make in
            our lives, and tools that can help us pivot when we need to.
            <br /> The ideas, tools and suggestions stemming from{" "}
            <span className="lg:desktop-paragraph-juicy px-1">
              The Juicy Pause
            </span>{" "}
            interviews are based upon actual experience and medical or
            professional training of my guests. Every attempt has been made to
            offer accurate examples, tools and information to our listeners. Our
            suggestions are not, I repeat, not meant to be a substitute for
            proper medical evaluation and care by qualified licensed medical
            professionals.
          </p>
          <p>
            Each woman’s health needs, risks, and goals are different, and
            should be developed with medical supervision, for personalized
            advice, answers to specific medical questions, and individual
            recommendations.
          </p>
          <p>
            This podcast does <strong>NOT</strong> advocate beginning any new
            treatment, supplement, over the counter hormone preparations, and/or
            athletic movement training without first consulting your health care
            practitioner. This podcast is intended for educational and sharing
            actual experience purposes only. My goal is to make sure that women
            everywhere, begin to take their own body, mind and spirit to a new
            level of vitality, as we age and step into the Wisdom Years of our
            lives.
          </p>
        </div>
        <iframe
          title="The Juicy Pause Podcast"
          className="rounded-lg max-w-xs lg:max-w-xl mt-8"
          src="https://open.spotify.com/embed/episode/5srnvvmV7qFfWAoU3TsrA3?utm_source=generator"
          width="100%"
          height="232"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>

        <LatestEpisodes />
        <ShareSocial />
        <Contribute />
      </div>
      {/* Footer */}
      <div className="relative bg-secondaryBackground flex flex-col items-center text-secondary py-16 ">
        <div className="text-center flex flex-col gap-4">
          <h2 className="mobile-subtitle lg:desktop-subtitle ">
            Get in touch!
          </h2>
          <p className="max-w-mobile  mobile-highlights lg:hidden text-center">
            If you have a subject and would like to be interviewed or you have a
            speaker suggestion, please send a message.
          </p>
          <p className="max-w-6xl desktop-highlights hidden lg:block">
            If you have a subject and would like to be interviewed or you have a
            speaker, who may be of intertest for the show and their contact
             information, please send  a message. 
          </p>
          <div className="flex items-center gap-1 mx-auto">
            <div className="lg:hidden">
              <IconMail />
            </div>
            <div className="hidden lg:block">
              <IconMailSplash />
            </div>
            <p className="cards-cta lg:navbar mt-0.5">
              {" "}
              madameflore@yahoo.co.uk
            </p>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 lg:right-0 scale-75 -translate-x-32 translate-y-4 lg:translate-y-0 lg:scale-100 z-10">
          <StaticImage
            height={120}
            src="../../../images/BackgroundElements/Home/LemonFooter.png"
            alt="Lemon"
            placeholder="tracedSVG"
          />
        </div>
      </div>
    </>
  )
}

export default Podcast
