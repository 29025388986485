import React from "react"

import { ButtonContribute, ButtonContributeCoffee } from "../../../Buttons"

const Contribute = () => {
  return (
    <div className="block mx-auto">
      <div className=" flex flex-col items-center justify-center gap-4">
        <p className="desktop-title text-center max-w-md mx-auto">Contribute</p>
        <p className="lg:desktop-paragraph text-secondary max-w-sm text-center lg:max-w-full pb-4">
          If you like what you hear, please help us reach out further through
          your contribution. We greatly appreciaty it!
        </p>
        <div className=" flex flex-col lg:flex-row gap-8 lg:gap-16">
          <ButtonContribute />
          <ButtonContributeCoffee />
        </div>
      </div>
    </div>
  )
}

export default Contribute
