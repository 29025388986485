import React from "react";

import Layout from "../layout/Layout";

import Seo from "../components/seo";
import Podcast from "../components/sections/Podcast";

const PodcastPage = () => {
  return (
    <Layout>
      <Seo title="About" />
      <Podcast />
    </Layout>
  );
};

export default PodcastPage;
